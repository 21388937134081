import { StatusMessage } from 'components';
import AssignmentsRefresh from '../AssignmentsRefresh/AssignmentsRefresh';
import TableActions from './components/TableActions/TableActions';
import DesktopAssigmentsTable from './components/DesktopAssigmentsTable/DesktopAssigmentsTable';
import { useAppSelector } from 'store';
import { IModalData } from 'pages/ResourceAssignments/ResourceAssignments';
import { IDomain } from 'interfaces';
import styles from './DesktopAssignments.module.scss';
import AssignmentsEmpty from '../AssignmentsEmpty/AssignmentsEmpty';
import { SerializedError } from '@reduxjs/toolkit';

interface Props {
  domain: IDomain;
  openManageAssignmentsModal: (data: IModalData) => void;
}

const DesktopAssignments = ({ domain, openManageAssignmentsModal }: Props) => {
  const deskAssignments = useAppSelector((state) => state.resourceAssignments.desktopAssignmentsData);
  const { isError, error } = useAppSelector((state) => state.resourceAssignments.getDesktopAssignmentsRequest);
  const { isLoading: isLoadingOnPremises } = useAppSelector(
    (state) => state.resourceAssignments.refreshOnPremiseDataRequest,
  );

  const isEmpty =
    !deskAssignments?.data.length &&
    !deskAssignments?.queryConfig.searchQuery &&
    deskAssignments?.queryConfig.viewBy !== 'Computers';

  if (isEmpty) {
    return <AssignmentsEmpty domain={domain} />;
  }

  if (isError) {
    return <StatusMessage error>{(error as SerializedError).message}</StatusMessage>;
  }

  const tableWrapStyle =
    deskAssignments?.data.length || deskAssignments?.queryConfig.searchQuery ? {} : { height: 'calc(100% - 5.5em)' };

  return (
    <>
      <header className={styles.header}>
        <TableActions domainId={domain.id} />
        <AssignmentsRefresh domain={domain} isRefreshing={isLoadingOnPremises} />
      </header>

      <div style={tableWrapStyle}>
        <DesktopAssigmentsTable openManageAssignmentsModal={openManageAssignmentsModal} domain={domain} />
      </div>
    </>
  );
};

export default DesktopAssignments;

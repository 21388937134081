import { useState } from 'react';
import { dateFormatterShort } from 'helpers';
import { ExclamationIcon } from 'assets/img';
import { corpAdmin, corpTechAdmin, freePersonalUser, personalUser, spTechAdmin } from 'consts';
import { notify } from 'utils';
import LicensesPersonal from './components/LicensesPersonal/LicensesPersonal';
import LicensesInfo from './components/LicensesInfo/LicensesInfo';
import TotalLicenses from './components/TotalLicenses/TotalLicenses';
import LicensesControls from './components/LicensesControls/LicensesControls';
import LicensesLinks from './components/LicensesLinks/LicensesLinks';
import { checkLicenses } from '../../utils/checkLicenses';
import styles from './PrimaryDomain.module.scss';
import { useAppDispatch, useAppSelector } from 'store';
import { ICorporateSubscription } from 'interfaces';
import { updateSubscription } from 'store/slices/subscriptions';

export interface IWarning {
  isActive: boolean;
  field?: string[] | string;
  content?: JSX.Element;
}

interface Props {
  role: string;
  email: string;
}

const PrimaryDomain = ({ role, email }: Props) => {
  const dispatch = useAppDispatch();
  const licenses = useAppSelector((state) => state.subscriptions.licenses as ICorporateSubscription);

  const [licensesState, setLicensesState] = useState<ICorporateSubscription>(licenses);
  const [warning, setWarning] = useState<IWarning>({ isActive: false });
  const minimumLicensesCount = role === corpAdmin ? 20 : 10;

  const handleUpdateSubscription = async () => {
    try {
      const { isLessRdp, isLessBitlocker, isMoreRdp, isMoreBitlocker } = checkLicenses(
        licenses,
        licensesState,
        minimumLicensesCount,
      );

      if (isLessRdp || isLessBitlocker || isMoreRdp || isMoreBitlocker) {
        const fieldNew = [];
        if (isLessRdp || isMoreRdp) fieldNew.push('numberOfRdpLicenses');
        if (isLessBitlocker || isMoreBitlocker) fieldNew.push('numberOfBitLockerLicenses');

        let message = '';
        if (isLessRdp || isLessBitlocker) message = "You can't reduce minimum number of licenses";
        if (isMoreRdp || isMoreBitlocker) message = "You can't buy more than 1000000 licenses";

        if (!warning.isActive) {
          setWarning({
            isActive: true,
            field: fieldNew,
            content: (
              <>
                <ExclamationIcon />
                {message}
              </>
            ),
          });
          setTimeout(() => setWarning({ isActive: false }), 3000);
        }
      } else {
        const data = [{ ...licensesState.spDomain }, ...licensesState.customerDomains];
        await dispatch(updateSubscription(data)).unwrap();
        notify.success('Subscription Updated');
      }
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  const canceledLicensesMsg = `License(s) will expire on <b>${dateFormatterShort(licenses.nextBillingDate)}</b>`;

  if ([personalUser, freePersonalUser].includes(role)) {
    return <LicensesPersonal email={email} />;
  }

  return (
    <>
      {!licenses.isSubscriptionActive && <div className={styles.backdrop} />}

      {![spTechAdmin, corpTechAdmin].includes(role) && (
        <LicensesInfo licensesState={licensesState} updateSubscription={handleUpdateSubscription} />
      )}

      <div className={styles.wrapper}>
        <TotalLicenses licensesState={licensesState} role={role} canceledLicensesMsg={canceledLicensesMsg} />
        <LicensesControls
          licensesState={licensesState}
          role={role}
          setLicensesState={setLicensesState}
          warning={warning}
          setWarning={setWarning}
          minimumLicensesCount={minimumLicensesCount}
        />
      </div>

      {![spTechAdmin, corpTechAdmin].includes(role) && !licenses.isAutomatedLicensingEnabled && (
        <LicensesLinks showAllocationMessage={licensesState.showAllocationMessage} />
      )}
    </>
  );
};

export default PrimaryDomain;

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { getDetailedReportStatus } from 'helpers';
import { createExtraReducersForResponses, createHttpRequestInitResult, http } from 'helpers/http';
import {
  IAssigmentsReports,
  IGenerateLicensingReport,
  IGenerateUserAssignmentsReportPayload,
  IHttpRequestResult,
  ILicenseReport,
  IPageMeta,
  IQueryConfig,
  IReportStatusData,
} from 'interfaces';
import {
  IAnalyticsAuthentication,
  IAnalyticsAuthenticationPage,
  IAnalyticsComputer,
  IAnalyticsComputers,
  IAnalyticsComputersDetailed,
  IAnalyticsComputersGroup,
  IAnalyticsComputersGroups,
  IAnalyticsLicenses,
  IAnalyticsRdpАttempt,
  IAnalyticsUser,
  IAnalyticsUsers,
} from 'interfaces/analytics';
import { RootState } from 'store/store';
import { prepareTimeRangeConfiguration, queryFormatter, returnFileFromResponse } from 'utils';

interface IAnalyticsComputerDto {
  machineId: string;
  name: string;
  groups: string[];
  osType: string;
  origin: number;
  isAzureJoined: boolean;
}

export interface IAnalyticsLoginStatsPayload {
  domainId: string;
  start?: Date;
  end?: Date;
  userIds: string[] | null;
}

export interface IAnalyticsAuthenticationPageData {
  data: IAnalyticsAuthenticationPage[];
  pageMeta: IPageMeta;
  queryConfig: IQueryConfig;
  payload: string;
}

export interface IAnalyticsRdpAttemptsPageData {
  data: IAnalyticsRdpАttempt[];
  pageMeta: IPageMeta;
  queryConfig: IQueryConfig;
  payload: string;
}

export interface IAnalyticsAuthenticationData {
  data: IAnalyticsAuthentication;
  queryConfig: IQueryConfig;
  payload: string;
}

export const getAnalyticsUsers = createAsyncThunk(
  'analytics/getAnalyticsUsers',
  async ({ domainId }: { domainId: string }) => {
    const response: AxiosResponse<IAnalyticsUsers> = await http.get(`/api/analytics/users?domainId=${domainId}`);
    return response.data;
  },
);

export const getAnalyticsAuthentication = createAsyncThunk(
  'analytics/getAnalyticsAuthentication',
  async (
    {
      payload,
      query,
      _background = false,
    }: { payload?: IAnalyticsLoginStatsPayload; query?: IQueryConfig; _background?: boolean },
    thunkAPI,
  ) => {
    const state = thunkAPI.getState() as RootState;
    const queryConfig = state.analytics.getAnalyticsAuthenticationRequest.data?.queryConfig || {};
    const payloadPrev = state.analytics.getAnalyticsAuthenticationRequest.data?.payload || '{}';
    const queryConfigNew = { ...queryConfig, ...query };
    const payloadNew = { ...JSON.parse(payloadPrev), ...payload };
    const response: AxiosResponse<IAnalyticsAuthentication> = await http.post(`/api/analytics/authentication`, {
      ...payloadNew,
      ...queryConfigNew,
    });
    const data: IAnalyticsAuthenticationData = {
      data: response.data,
      queryConfig: queryConfigNew,
      payload: JSON.stringify(payloadNew),
    };
    return data;
  },
);

export const getAnalyticsAuthenticationPage = createAsyncThunk(
  'analytics/getAnalyticsAuthenticationPage',
  async (
    {
      payload,
      query,
      _background = false,
    }: { payload?: IAnalyticsLoginStatsPayload; query?: IQueryConfig; _background?: boolean },
    thunkAPI,
  ) => {
    const state = thunkAPI.getState() as RootState;
    const queryConfig = state.analytics.getAnalyticsAuthenticationPageRequest.data?.queryConfig || {};
    const payloadPrev = state.analytics.getAnalyticsAuthenticationPageRequest.data?.payload || '{}';
    const queryConfigNew = { ...queryConfig, ...query };
    const payloadNew = { ...JSON.parse(payloadPrev), ...payload };
    const response: AxiosResponse<IAnalyticsAuthenticationPage[]> = await http.post(
      `/api/analytics/page-authentication`,
      {
        ...payloadNew,
        ...queryConfigNew,
      },
    );
    const pageMeta = response?.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null;
    const data: IAnalyticsAuthenticationPageData = {
      data: response.data,
      pageMeta,
      queryConfig: queryConfigNew,
      payload: JSON.stringify(payloadNew),
    };
    return data;
  },
);

export const getAnalyticsRdpAttempts = createAsyncThunk(
  'analytics/getAnalyticsRdpAttempts',
  async (
    {
      payload,
      query,
      _background = false,
    }: { payload?: IAnalyticsLoginStatsPayload; query?: IQueryConfig; _background?: boolean },
    thunkAPI,
  ) => {
    const state = thunkAPI.getState() as RootState;
    const queryConfig = state.analytics.getAnalyticsRdpAttemptsRequest.data?.queryConfig || {};
    const payloadPrev = state.analytics.getAnalyticsRdpAttemptsRequest.data?.payload || '{}';
    const queryConfigNew = { ...queryConfig, ...query };
    const payloadNew = { ...JSON.parse(payloadPrev), ...payload };

    const response: AxiosResponse<IAnalyticsAuthentication> = await http.post(`/api/analytics/rdpattempts`, {
      ...payloadNew,
      ...queryConfigNew,
    });
    const data: IAnalyticsAuthenticationData = {
      data: response.data,
      queryConfig: queryConfigNew,
      payload: JSON.stringify(payloadNew),
    };
    return data;
  },
);

export const getAnalyticsRdpAttemptsPage = createAsyncThunk(
  'analytics/getAnalyticsRdpAttemptsPage',
  async (
    {
      payload,
      query,
      _background = false,
    }: { payload?: IAnalyticsLoginStatsPayload; query?: IQueryConfig; _background?: boolean },
    thunkAPI,
  ) => {
    const state = thunkAPI.getState() as RootState;
    const queryConfig = state.analytics.getAnalyticsRdpAttemptsPageRequest.data?.queryConfig || {};
    const payloadPrev = state.analytics.getAnalyticsRdpAttemptsPageRequest.data?.payload || '{}';
    const queryConfigNew = { ...queryConfig, ...query };
    const payloadNew = { ...JSON.parse(payloadPrev), ...payload };

    const response: AxiosResponse<IAnalyticsRdpАttempt[]> = await http.post(`/api/analytics/page-rdpattempts`, {
      ...payloadNew,
      ...queryConfigNew,
    });
    const pageMeta = response?.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null;
    const data: IAnalyticsRdpAttemptsPageData = {
      data: response.data,
      pageMeta,
      queryConfig: queryConfigNew,
      payload: JSON.stringify(payloadNew),
    };
    return data;
  },
);

export const getAnalyticsComputers = createAsyncThunk(
  'analytics/getAnalyticsComputers',
  async ({ domainId, _background = false }: { domainId: string; _background?: boolean }) => {
    const response: AxiosResponse<IAnalyticsComputers> = await http.get(
      `/api/analytics/computers?domainId=${domainId}`,
    );
    return response.data;
  },
);

export const getAnalyticsComputersDetailed = createAsyncThunk(
  'analytics/getAnalyticsComputersDetailed',
  async ({ query, _background = false }: { query?: IQueryConfig; _background?: boolean }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const queryConfig = state.analytics.getAnalyticsComputersDetailedRequest.data?.queryConfig || {};
      const queryConfigNew = { ...queryConfig, ...query };
      const response: AxiosResponse<IAnalyticsComputerDto[]> = await http.get(
        queryFormatter('/api/analytics/computersDetailed', queryConfigNew),
      );
      const pageMeta = response.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null;
      const adapteredData: IAnalyticsComputer[] = response.data.map((item) => ({
        ...item,
        type: item.origin === 0 ? 'sentry' : 'secureConnect',
      }));
      const data: IAnalyticsComputersDetailed = {
        data: adapteredData,
        pageMeta,
        queryConfig: queryConfigNew,
      };
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getAnalyticsComputersGroupsDetailed = createAsyncThunk(
  'analytics/getAnalyticsComputersGroupsDetailed',
  async ({ query, _background = false }: { query?: IQueryConfig; _background?: boolean }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const queryConfig = state.analytics.getAnalyticsComputersGroupsDetailedRequest.data?.queryConfig || {};
      const queryConfigNew = { ...queryConfig, ...query };
      const response: AxiosResponse<IAnalyticsComputersGroup[]> = await http.get(
        queryFormatter('/api/analytics/computerGroups', queryConfigNew),
      );
      const pageMeta = response.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null;
      const data: IAnalyticsComputersGroups = {
        data: response.data,
        pageMeta,
        queryConfig: queryConfigNew,
      };
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getAnalyticsLicenses = createAsyncThunk(
  'analytics/getAnalyticsLicenses',
  async ({ domainId, type = 'rdp' }: { domainId: string; type: 'rdp' | 'bitlocker' }) => {
    const response: AxiosResponse<IAnalyticsLicenses> = await http.get(`/api/analytics/${type}?domainId=${domainId}`);
    return response.data;
  },
);

export const getLicensesReports = createAsyncThunk(
  'analytics/getLicensesReports',
  async ({
    domainId,
    licenseType,
    _background = false,
  }: {
    domainId: string;
    licenseType: string;
    _background?: boolean;
  }) => {
    const response: AxiosResponse<ILicenseReport> = await http.post('/api/reports/licenses', {
      domainId,
      licenseType,
    });
    return response.data;
  },
);

export const downloadAuthReport = createAsyncThunk(
  'analytics/downloadAuthReport',
  async ({ domainId }: { domainId: string }, thunkAPI) => {
    const range = (thunkAPI.getState() as RootState).analytics.selectedRange;
    const timeRange = prepareTimeRangeConfiguration();
    const rangeData = timeRange.find((i) => i.text.toString() === range);
    if (!rangeData) {
      throw new Error('Invalid range');
    }
    const payload = {
      users: (thunkAPI.getState() as RootState).analytics.selectedUsers,
      start: rangeData.startDate,
      end: rangeData.endDate,
      domainId,
    };
    const response: AxiosResponse = await http.post('/api/ActivityReport/Prepare', payload);
    return returnFileFromResponse(response);
  },
);

export const getAssigmentsReports = createAsyncThunk(
  'analytics/getAssigmentsReports',
  async ({ domainId }: { domainId: string }) => {
    const response: AxiosResponse<IAssigmentsReports> = await http.get(
      `/api/reports/GetAssignmentsReportsStatus?domainId=${domainId}`,
    );
    const { isReportGenerated, isReportGenerating } = getDetailedReportStatus(response.status);
    if (isReportGenerated) {
      return response.data;
    }
    if (isReportGenerating) {
      return false;
    }
  },
);

export const getReportStatusData = createAsyncThunk(
  'analytics/getReportStatusData',
  async ({ domainId }: { domainId: string }) => {
    const response: AxiosResponse<IReportStatusData> = await http.get(
      `/api/reports/DetailedReportStatus?domainId=${domainId}`,
    );
    return response.data;
  },
);

export const generateUserAssignmentsReport = createAsyncThunk(
  'analytics/generateUserAssignmentsReport',
  async ({ domainId, domainIds, includeMachines, includeApplications }: IGenerateUserAssignmentsReportPayload) => {
    const response: AxiosResponse<IReportStatusData> = await http.post(
      `/api/reports/GenerateUserAssignmentsReport?domainId=${domainId}`,
      {
        domainIds,
        includeMachines,
        includeApplications,
      },
      { timeout: 10000 },
    );
    return response.data;
  },
);

export const generateMachineAssignmentsReport = createAsyncThunk(
  'analytics/generateMachineAssignmentsReport',
  async ({ domainId, domainIds }: Partial<IGenerateUserAssignmentsReportPayload>) => {
    const response: AxiosResponse<IReportStatusData> = await http.post(
      `/api/reports/GenerateMachineAssignmentsReport?domainId=${domainId}`,
      { domainIds },
      { timeout: 10000 },
    );
    return response.data;
  },
);

export const generateApplicationAssignmentsReport = createAsyncThunk(
  'analytics/generateApplicationAssignmentsReport',
  async ({ domainId, domainIds }: Partial<IGenerateUserAssignmentsReportPayload>) => {
    const response: AxiosResponse<IReportStatusData> = await http.post(
      `/api/reports/GenerateApplicationAssignmentsReport?domainId=${domainId}`,
      { domainIds },
      { timeout: 10000 },
    );
    return response.data;
  },
);

export const generateLicensingReport = createAsyncThunk(
  'analytics/generateLicensingReport',
  async ({ domainId, domainIds, isMFAStatusChecked, isLicenseChecked, isADGroupChecked }: IGenerateLicensingReport) => {
    const response: AxiosResponse<IReportStatusData> = await http.post(
      `/api/reports/GenerateDetailedReport?domainId=${domainId}`,
      {
        domainIds,
        IncludeMfaStatus: isMFAStatusChecked,
        IncludeLicenseIssueDate: isLicenseChecked,
        IncludeDirectGroup: isADGroupChecked,
      },
      { timeout: 10000 },
    );
    return response.data;
  },
);

interface IAnalyticsState {
  getAnalyticsUsersRequest: IHttpRequestResult<IAnalyticsUsers>;
  getAnalyticsAuthenticationRequest: IHttpRequestResult<IAnalyticsAuthenticationData>;
  getAnalyticsAuthenticationPageRequest: IHttpRequestResult<IAnalyticsAuthenticationPageData>;
  getAnalyticsRdpAttemptsRequest: IHttpRequestResult<IAnalyticsAuthenticationData>;
  getAnalyticsRdpAttemptsPageRequest: IHttpRequestResult<IAnalyticsRdpAttemptsPageData>;
  getAnalyticsComputersRequest: IHttpRequestResult<IAnalyticsComputers>;
  getAnalyticsComputersDetailedRequest: IHttpRequestResult<IAnalyticsComputersDetailed>;
  getAnalyticsComputersGroupsDetailedRequest: IHttpRequestResult<IAnalyticsComputersGroups>;
  getAnalyticsLicensesRequest: IHttpRequestResult<IAnalyticsLicenses>;
  getReportStatusDataRequest: IHttpRequestResult<IReportStatusData>;
  getLicensesReportsRequest: IHttpRequestResult<ILicenseReport>;
  downloadAuthReportRequest: IHttpRequestResult<void>;
  getAssigmentsReportsRequest: IHttpRequestResult<IAssigmentsReports>;
  generateUserAssignmentsReportRequest: IHttpRequestResult<IReportStatusData>;
  generateMachineAssignmentsReportRequest: IHttpRequestResult<IReportStatusData>;
  generateApplicationAssignmentsReportRequest: IHttpRequestResult<IReportStatusData>;
  generateLicensingReportRequest: IHttpRequestResult<IReportStatusData>;
  reports: IAssigmentsReports | null;
  licenses: ILicenseReport[];
  selectedLicenseType: string;
  users: IAnalyticsUser[];
  selectedUsers: string[];
  selectedRange: string;
  isFastGenerate: boolean;
  reportLoading: {
    userAssignments: boolean;
    machineAssignmnets: boolean;
    appAssignments: boolean;
  };
}

const initialState: IAnalyticsState = {
  getAnalyticsUsersRequest: createHttpRequestInitResult(),
  getAnalyticsAuthenticationRequest: createHttpRequestInitResult(),
  getAnalyticsAuthenticationPageRequest: createHttpRequestInitResult(),
  getAnalyticsRdpAttemptsRequest: createHttpRequestInitResult(),
  getAnalyticsRdpAttemptsPageRequest: createHttpRequestInitResult(),
  getAnalyticsComputersRequest: createHttpRequestInitResult(),
  getAnalyticsComputersDetailedRequest: createHttpRequestInitResult(),
  getAnalyticsComputersGroupsDetailedRequest: createHttpRequestInitResult(),
  getAnalyticsLicensesRequest: createHttpRequestInitResult(),
  getReportStatusDataRequest: createHttpRequestInitResult(),
  getLicensesReportsRequest: createHttpRequestInitResult(),
  downloadAuthReportRequest: createHttpRequestInitResult(),
  getAssigmentsReportsRequest: createHttpRequestInitResult(),
  generateUserAssignmentsReportRequest: createHttpRequestInitResult(),
  generateMachineAssignmentsReportRequest: createHttpRequestInitResult(),
  generateApplicationAssignmentsReportRequest: createHttpRequestInitResult(),
  generateLicensingReportRequest: createHttpRequestInitResult(),
  reports: null,
  licenses: [],
  selectedLicenseType: 'SecureRDP',
  users: [],
  selectedUsers: [],
  selectedRange: 'Last 7 days',
  isFastGenerate: true,
  reportLoading: {
    userAssignments: false,
    machineAssignmnets: false,
    appAssignments: false,
  },
};

export const analytics = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    changeSelectedLicenseType(state, action) {
      state.selectedLicenseType = action.payload;
    },
    changeIsFastGenerate(state, action) {
      state.isFastGenerate = action.payload;
    },
    changeReportLoading(state, action) {
      state.reportLoading = {
        ...state.reportLoading,
        [action.payload]: action.payload.state,
      };
    },
    updateSelectedRange: (state, action: PayloadAction<string>) => {
      state.selectedRange = action.payload;
    },
    updateSelectedUsers: (state, action: PayloadAction<string[]>) => {
      state.selectedUsers = action.payload;
    },
    clearAnalyticsStore: () => initialState,
  },
  extraReducers: (builder) => {
    createExtraReducersForResponses<IAnalyticsState>(
      builder,
      getAnalyticsUsers,
      'getAnalyticsUsersRequest',
      (state, action: PayloadAction<{ users: IAnalyticsUser[] }>) => {
        state.users = action.payload.users;
        state.selectedUsers = action.payload.users.map((i) => i.userId);
      },
    );
    createExtraReducersForResponses<IAnalyticsState>(
      builder,
      getAnalyticsAuthentication,
      'getAnalyticsAuthenticationRequest',
    );
    createExtraReducersForResponses<IAnalyticsState>(
      builder,
      getAnalyticsAuthenticationPage,
      'getAnalyticsAuthenticationPageRequest',
    );
    createExtraReducersForResponses<IAnalyticsState>(
      builder,
      getAnalyticsRdpAttempts,
      'getAnalyticsRdpAttemptsRequest',
    );
    createExtraReducersForResponses<IAnalyticsState>(
      builder,
      getAnalyticsRdpAttemptsPage,
      'getAnalyticsRdpAttemptsPageRequest',
    );
    createExtraReducersForResponses<IAnalyticsState>(builder, getReportStatusData, 'getReportStatusDataRequest');
    createExtraReducersForResponses<IAnalyticsState>(builder, getAnalyticsComputers, 'getAnalyticsComputersRequest');
    createExtraReducersForResponses<IAnalyticsState>(
      builder,
      getAnalyticsComputersDetailed,
      'getAnalyticsComputersDetailedRequest',
    );
    createExtraReducersForResponses<IAnalyticsState>(
      builder,
      getAnalyticsComputersGroupsDetailed,
      'getAnalyticsComputersGroupsDetailedRequest',
    );
    createExtraReducersForResponses<IAnalyticsState>(builder, getAnalyticsLicenses, 'getAnalyticsLicensesRequest');
    createExtraReducersForResponses<IAnalyticsState>(
      builder,
      getLicensesReports,
      'getLicensesReportsRequest',
      (state, action: PayloadAction<ILicenseReport[], any, { arg: { domainId: string } }>) => {
        const data = action.payload;
        const { domainId } = action.meta.arg;
        state.licenses = data.sort((a, b) => (a.domainId === domainId ? -1 : b.domainId === domainId ? 1 : 0));
      },
    );
    createExtraReducersForResponses<IAnalyticsState>(builder, downloadAuthReport, 'downloadAuthReportRequest');
    createExtraReducersForResponses<IAnalyticsState>(
      builder,
      getAssigmentsReports,
      'getAssigmentsReportsRequest',
      (state, action: PayloadAction<IAssigmentsReports>) => {
        state.reports = action.payload;
      },
    );
    createExtraReducersForResponses<IAnalyticsState>(
      builder,
      generateUserAssignmentsReport,
      'generateUserAssignmentsReportRequest',
      (state, action: PayloadAction<IReportStatusData>) => {
        if (state.reports) {
          state.reports = {
            ...state.reports,
            userAssignments: action.payload,
          };
        }
      },
    );
    createExtraReducersForResponses<IAnalyticsState>(
      builder,
      generateMachineAssignmentsReport,
      'generateMachineAssignmentsReportRequest',
      (state, action: PayloadAction<IReportStatusData>) => {
        if (state.reports) {
          state.reports = {
            ...state.reports,
            machineAssignmnets: action.payload,
          };
        }
        return state;
      },
    );
    createExtraReducersForResponses<IAnalyticsState>(
      builder,
      generateApplicationAssignmentsReport,
      'generateApplicationAssignmentsReportRequest',
      (state, action: PayloadAction<IReportStatusData>) => {
        if (state.reports) {
          state.reports = {
            ...state.reports,
            appAssignments: action.payload,
          };
        }
        return state;
      },
    );
    createExtraReducersForResponses<IAnalyticsState>(
      builder,
      generateLicensingReport,
      'generateLicensingReportRequest',
    );
  },
});

export const {
  changeSelectedLicenseType,
  changeIsFastGenerate,
  changeReportLoading,
  updateSelectedRange,
  updateSelectedUsers,
  clearAnalyticsStore,
} = analytics.actions;

export default analytics.reducer;
